import { library } from '@fortawesome/fontawesome-svg-core';

// Brands
import {} from '@fortawesome/free-brands-svg-icons';

// Solid
import {
    faAngleRight,
    faAngleDown,
    faAngleUp,
    faBan,
    faBars,
    faCaretDown,
    faCaretRight,
    faCheck,
    faCertificate,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faCircleNotch,
    faCoffee,
    faCog,
    faDownload,
    faEject,
    faExchangeAlt,
    faExclamationTriangle,
    faExclamationCircle,
    faHistory,
    faInfo,
    faLock,
    faLongArrowRight,
    faMinus,
    faPlus,
    faPrint,
    faQuestionCircle,
    faRetweet,
    faSearch,
    faSignOutAlt,
    faSpinner,
    faThumbtack,
    faTimes,
    faExclamation,
    faPercent,
    faUndo,
    faUnlock,
    faUserPlus,
    faUsers,
    faUserTimes,
    faUtensils,
    faTrashAlt,
    faQuoteRight,
    faGlobe,
    faFilter,
    faPlay,
    faStop,
    faCircle,
    faUpload,
    faPencil,
    faExternalLink,
    faChartPieAlt,
    faDumpster,
    faEdit,
} from '@fortawesome/pro-solid-svg-icons';

// Regular
import {
    faCalendar as farCalendar,
    faCheckCircle as farCheckCircle,
    faClock as farClock,
    faTrashAlt as farTrashAlt,
    faPrint as farPrint,
    faThList as farThList,
    faLock as farLock,
    faUnlock as farUnlock,
    faPenAlt as farPenAlt,
    faBold as farBold,
    faItalic as farItalic,
    faUnderline as farUnderline,
    faListUl as farListUl,
    faListOl as farListOl,
    faMinus as farMinus,
    faStethoscope as farStethoscope,
    faExclamationCircle as farExclamationCircle,
    faCalendarAlt as farCalendarAlt,
    faPencil as farPencil,
    faClone as farClone,
    faEllipsisV as farEllipsisV,
    faEllipsisH as farEllipsisH,
    faRocket as farRocket,
    faCalendarMinus as farCalendarMinus,
    faMoneyBill as farMoneyBill,
} from '@fortawesome/pro-regular-svg-icons';

// Light
import {
    faTimes as falTimes,
    faFilePdf as falFilePdf,
    faFileExcel as falFileExcel,
    faLanguage as falLanguage,
    faFileAlt as falFileAlt,
    faUser as falUser,
    faUserPlus as falUserPlus,
    faCalendarPlus as falCalendarPlus,
    faCalendarMinus as falCalendarMinus,
    faBuilding as falBuilding,
    faMapMarker as falMapMarker,
    faIdCard as falIdCard,
    faBarcode as falBarcode,
    faCalendar as falCalendar,
    faTasks as falTasks,
    faEllipsisV as falEllipsisV,
    faSpinnerThird as falSpinnerThird,
    faBallotCheck as falBallotCheck,
    faExpandAlt as falExpandAlt,
    faPlus as falPlus,
    faMinus as falMinus,
    faToggleOff as falToggleOff,
    faToggleOn as falToggleOn,
    faExternalLink as falExternalLink,
    faDownload as falDownload,
    faUpload as falUpload,
    faMobile as falMobile,
} from '@fortawesome/pro-light-svg-icons';

// Duotone
import {
    faAppleAlt as fadAppleAlt,
    faComments as fadComments,
    faExclamationSquare as fadExclamationSquare,
    faCalendarDay as fadCalendarDay,
    faEnvelope as fadEnvelope,
    faWeight as fadWeight,
    faSave as fadSave,
    faEdit as fadEdit,
    faTrashAlt as fadTrashAlt,
    faSpinner as fadSpinner,
    faFileChartPie as fadFileChartPie,
    faInfoCircle as fadInfoCircle,
    faClipboardList as fadClipboardList,
    faThumbtack as fadThumbtack,
    faCalendarWeek as fadCalendarWeek,
    faGlobe as fadGlobe,
    faPallet as fadPallet,
    faUser as fadUser,
    faStoreAltSlash as fadStoreAltSlash,
} from '@fortawesome/pro-duotone-svg-icons';

// Solid
library.add(
    faAngleRight,
    faAngleDown,
    faAngleUp,
    faBan,
    faBars,
    faCaretDown,
    faCaretRight,
    faCertificate,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faCircleNotch,
    faCoffee,
    faCog,
    faDownload,
    faEject,
    faExchangeAlt,
    faExclamationTriangle,
    faExclamationCircle,
    faHistory,
    faInfo,
    faLock,
    faLongArrowRight,
    faMinus,
    faPercent,
    faPlus,
    faPrint,
    faQuestionCircle,
    faRetweet,
    faSearch,
    faSignOutAlt,
    faSpinner,
    faThumbtack,
    faTimes,
    faUndo,
    faCog,
    faCircleNotch,
    faTimes,
    faAngleRight,
    faExclamation,
    faPercent,
    faUnlock,
    faUserPlus,
    faUsers,
    faUserTimes,
    faUtensils,
    faTrashAlt,
    faQuoteRight,
    faGlobe,
    faFilter,
    faPlay,
    faStop,
    faCircle,
    faUpload,
    faPencil,
    faExternalLink,
    faChartPieAlt,
    faDumpster,
    faEdit,
);

// Regular
library.add(
    farCalendar,
    farCheckCircle,
    farClock,
    farTrashAlt,
    farPrint,
    farThList,
    farLock,
    farUnlock,
    farPenAlt,
    farBold,
    farItalic,
    farUnderline,
    farListUl,
    farListOl,
    farMinus,
    farStethoscope,
    farExclamationCircle,
    farCalendarAlt,
    farPencil,
    farClone,
    farEllipsisV,
    farRocket,
    farCalendarMinus,
    farEllipsisH,
    farMoneyBill,
);

// Light
library.add(
    falTimes,
    falFilePdf,
    falFileExcel,
    falLanguage,
    falFileAlt,
    falUser,
    falUserPlus,
    falCalendarPlus,
    falCalendarMinus,
    falBuilding,
    falMapMarker,
    falIdCard,
    falBarcode,
    falCalendar,
    falTasks,
    falEllipsisV,
    falSpinnerThird,
    falBallotCheck,
    falExpandAlt,
    falPlus,
    falMinus,
    falToggleOff,
    falToggleOn,
    falExternalLink,
    falDownload,
    falUpload,
    falMobile,
);

// Duotone
library.add(
    fadAppleAlt,
    fadComments,
    fadExclamationSquare,
    fadCalendarDay,
    fadEnvelope,
    fadWeight,
    fadSave,
    fadEdit,
    fadTrashAlt,
    fadSpinner,
    fadFileChartPie,
    fadInfoCircle,
    fadClipboardList,
    fadThumbtack,
    fadCalendarWeek,
    fadGlobe,
    fadPallet,
    fadUser,
    fadStoreAltSlash,
);
