import MessageFormat from 'messageformat';

export class CustomFormatter {
    private locale: string;
    private formatter: MessageFormat;
    private caches: { [key: string]: (args: object) => string };

    constructor(locale = 'en') {
        this.locale = locale;
        this.formatter = new MessageFormat(this.locale, {
            customFormatters: {
                /* Usado para formatear partes del título de pestañas */
                titlepart: (v) => (v ? `${v} • ` : ''),
            },
        });
        this.caches = Object.create(null);
    }

    public interpolate(message: string, values: any) {
        let fn = this.caches[message];
        if (!fn) {
            fn = this.formatter.compile(message, this.locale);
            this.caches[message] = fn;
        }

        return [fn(values ?? {})];
    }
}
