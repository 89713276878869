import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { UnidadesProduccion_Acceso, UnidadesProduccion_Admin } from '../auth/permissions';

const ArticulosList = () => import(/* webpackChunkName: "materiales" */ './list/index.vue');
const ArticuloDetail = () => import(/* webpackChunkName: "materiales" */ './detail/index.vue');
const ArticuloImport = () => import(/* webpackChunkName: "materiales" */ './excel-importer/index.vue');

export default {
    path: 'articulos',
    name: 'home.articulos',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.articulos.list' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "materiales" */ './store');
        next();
    },
    meta: {
        permissions: [UnidadesProduccion_Acceso, UnidadesProduccion_Admin],
        anyPermissionValid: true,
        title: 'articulos',
    },
    children: [
        {
            path: '',
            name: 'home.articulos.list',
            component: ArticulosList,
        },
        {
            path: 'import',
            name: 'home.articulos.excel-importer',
            component: ArticuloImport,
        },
        {
            path: ':articuloId',
            name: 'home.articulos.detail',
            component: ArticuloDetail,
        },
    ],
} as RouteConfig;
